import { render, staticRenderFns } from "./PaperAdd.vue?vue&type=template&id=f97ed262&"
import script from "@/controllers/admin/homework/PaperAdd?v=2.1?vue&type=script&lang=ts&"
export * from "@/controllers/admin/homework/PaperAdd?v=2.1?vue&type=script&lang=ts&"
import style0 from "@/assets/css/backend.css?v=2.2?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports